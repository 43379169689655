import AdminendPanel from 'Layout/AdminPanel'

// dashboard components
const Dashboard = () => import( 'Views/adminPages/Dashboard' );
const Profile = () => import( 'Views/adminPages/Profile' );
const Companies = () => import( 'Views/adminPages/Companies' );
const Users = () => import( 'Views/adminPages/Users' );
const Data = () => import( 'Views/adminPages/Data' );
const CompanyData = () => import( 'Views/adminPages/CompanyData' );
const CompanyGalery = () => import( 'Views/adminPages/CompanyGalery' );
const Publications = () => import( 'Views/adminPages/Publications' );
const Categories = () => import( 'Views/adminPages/Categories' );
const Countries = () => import( 'Views/adminPages/Countries' );
const Statistics = () => import( 'Views/adminPages/Statistics' );
const Meetings = () => import( 'Views/adminPages/Meetings' );
const Directors = () => import( 'Views/adminPages/Directors' );
const StatementsAccount = () => import( 'Views/adminPages/StatementsAccount' );

export default {
   path: '/admin',
   name: 'AdminendPanel',
   component: AdminendPanel,
   redirect: '/admin/dashboard',
   meta: {
      title: 'Admin',
      requiresAuth: true,
      requiredRoles: [ 'super_admin', 'user' ]
   },
   children: [
      {
         path: '/admin/dashboard', name: 'Dashboard',
         component: Dashboard,
         meta: {
            title: 'Dashboard',
            requiresAuth: true,
            requiredRoles: [ 'super_admin', 'fiaf_admin', 'association_admin', 'user' ]
         }
      },
      {
         path: '/admin/profile', name: 'Profile',
         component: Profile,
         meta: {
            title: 'Perfil',
            requiresAuth: true,
            requiredRoles: ['super_admin', 'user']

         }
      },
      {
         path: '/admin/users', name: 'Users',
         component: Users,
         meta: {
            title: 'Usuarios',
            requiresAuth: true,
            requiredRoles: ['super_admin', 'fiaf_admin']
         }
      },
      {
         path: '/admin/franchise-associations', name: 'FranchisesAssociations',
         component: Companies,
         meta: {
            title: 'Franquicias',
            requiresAuth: true,
            requiredRoles: ['super_admin', 'fiaf_admin']
         },
         props: {
            companyType: 1
         }
      },
      {
         path: '/admin/franchise-associations/:id/franchises', name: 'Franchises',
         component: Companies,
         meta: {
            title: 'Franquicias',
            requiresAuth: true,
            requiredRoles: ['super_admin', 'fiaf_admin', 'association_admin']
         },
         props: {
            companyType: 0
         }
      },
      {
         path: '/admin/affiliated/my-association', name: 'MyAssociations',
         component: Companies,
         meta: {
            title: 'MyFranchisesAssociations',
            requiresAuth: true,
            requiredRoles: ['super_admin', 'association_admin']
         },
         props: {
            companyType: 1
         }
      },
      {
         path: '/admin/companies/data', name: 'CompaniesData',
         component: CompanyData,
         meta: {
            title: 'Ficha de Franquicia',
            requiresAuth: true,
            requiredRoles: ['super_admin', 'fiaf_admin', 'association_admin']
         }
      },
      {
         path: '/admin/data', name: 'Data',
         component: Data,
         meta: {
            title: 'Datos',
            requiresAuth: true,
            requiredRoles: ['super_admin', 'fiaf_admin']
         }
      },
      {
         path: '/admin/companies/galery', name: 'CompanyGalery',
         component: CompanyGalery,
         meta: {
            title: 'Galería de Imágenes',
            requiresAuth: true,
            requiredRoles: [ 'super_admin', 'fiaf_admin', 'association_admin']
         }
      },
      {
         path: '/admin/publications', name: 'AdminPublications',
         component: Publications,
         meta: {
            title: 'Publicaciones',
            requiresAuth: true,
            requiredRoles: ['super_admin', 'fiaf_admin', 'publisher']
         }
      },
      {
         path: '/admin/categories', name: 'Categories',
         component: Categories,
         meta: {
            title: 'Categories',
            requiresAuth: true,
            requiredRoles: ['super_admin', 'fiaf_admin']
         }
      },
      {
         path: '/admin/countries', name: 'Countries',
         component: Countries,
         meta: {
            title: 'Countries',
            requiresAuth: true,
            requiredRoles: ['super_admin', 'fiaf_admin']
         }
      },
      {
         path: '/admin/stats', name: 'Statistics',
         component: Statistics,
         meta: {
            title: 'Estadisticas',
            requiresAuth: true,
            requiredRoles: [ 'super_admin', 'fiaf_admin' ]
         }
      },
      {
         path: '/admin/meetings', name: 'Meetings',
         component: Meetings,
         meta: {
            title: 'Reuniones',
            requiresAuth: true,
            requiredRoles: [ 'super_admin', 'fiaf_admin' ]
         },
         props: {
            admin: 1
         }
      },
      {
         path: '/admin/affiliated/meetings', name: 'MyMeetings',
         component: Meetings,
         meta: {
            title: 'MyMeetings',
            requiresAuth: true,
            requiredRoles: ['super_admin', 'association_admin']
         },
         props: {
            admin: 0
         }
      },
      {
         path: '/admin/statement-accounts', name: 'StatementsAccount',
         component: StatementsAccount,
         meta: {
            title: 'StatementsAccount',
            requiresAuth: true,
            requiredRoles: [ 'super_admin', 'fiaf_admin' ]
         },
         props: {
            admin: 1
         }
      },
      {
         path: '/admin/affiliated/statement-accounts', name: 'MyStatementsAccount',
         component: StatementsAccount,
         meta: {
            title: 'MyStatementsAccount',
            requiresAuth: true,
            requiredRoles: ['super_admin', 'association_admin']
         },
         props: {
            admin: 0
         }
      },
      {
         path: '/admin/directors', name: 'Directors',
         component: Directors,
         meta: {
            title: 'Directors',
            requiresAuth: true,
            requiredRoles: [ 'super_admin', 'fiaf_admin' ]
         },
         props: {
            admin: 1,
            type: 1,
            association: 0
         }
      },
      {
         path: '/admin/previous-directors', name: 'PreviousDirectors',
         component: Directors,
         meta: {
            title: 'PreviousDirectors',
            requiresAuth: true,
            requiredRoles: [ 'super_admin', 'fiaf_admin' ]
         },
         props: {
            admin: 1,
            type: 2,
            association: 0
         }
      },
      {
         path: '/admin/companies/:id/directors', name: 'CompanyDirectors',
         component: Directors,
         meta: {
            title: 'CompanyDirectors',
            requiresAuth: true,
            requiredRoles: ['super_admin', 'fiaf_admin', 'association_admin']
         },
         props: {
            admin: 1,
            type: 1,
            association: 1
         }
      },
   ]
}
