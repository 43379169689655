<!-- App Main Structure -->
<template>
  <div id="wrapper">
    <!-- Header of Admin Panel -->
    <app-admin-header></app-admin-header>
    <div id="dashboard">
      <!-- Sidebar of Admin Panel -->
      <app-admin-sidebar></app-admin-sidebar>
      <!--Main Container -->
      <router-view :key="$route.fullPath" style=""></router-view>
    </div>
  </div>
</template>

<script>
  import AdminHeader from 'Components/TheHeader.vue'
  import AdminSidebar from 'Components/AdminSidebar.vue'

  export default {
    name: 'AdminPanel',
    components: {
      appAdminHeader: AdminHeader,
      appAdminSidebar: AdminSidebar,
    },
  }
</script>
