import * as mutationTypes from '../mutation-types';
import config from '@/config/index';

export const state = config.theme;

export const getters = {
  primary: ( state ) => state.primary,
  secondary: ( state ) => state.secondary,
  dark: ( state ) => state.dark,
  rtl: ( state ) => state.rtl,
  primaryBgText: ( state ) => state.primaryBgText,
  secondaryBgText: ( state ) => state.secondaryBgText,
};

export const mutations = {
  [mutationTypes.SET_DARK_MODE]: ( state, payload ) => {
    state.semidark = false;
    state.dark = payload !== undefined ? payload : !state.dark;
  },
};

export const actions = {
  setDarkTheme: ( { commit }, payload ) => {
    commit( mutationTypes.SET_DARK_MODE, payload );
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
