export const availableLocale = [
  {
    text: 'English',
    value: 'en',
  },
  {
    text: 'Español',
    value: 'es',
  },
  {
    text: 'Português',
    value: 'pt',
  },
];
export default {
  locale: 'es',
};
