import * as mutationTypes from '../mutation-types';
import config from '@/config/index';

import i18n from '@/plugins/i18n';

export const state = config.locale;
export const getters = {
  locale: state => state.locale
};

export const mutations = {
  [mutationTypes.SET_LOCALE]: ( state, payload ) => {
    const defaultLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'es'
    state.locale = payload !== undefined ? payload : defaultLang;
  }
};

export const actions = {
  setLocale: ( { commit }, payload ) => {
    i18n.locale = payload;
    localStorage.setItem('lang', payload)
    commit( mutationTypes.SET_LOCALE, payload );
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};

