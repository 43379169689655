import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from "@/lang/en";
import es from "@/lang/es";
import pt from "@/lang/pt";

Vue.use(VueI18n);

const messages = { en, es, pt };

// console.log(messages);

const i18n = new VueI18n({ locale: 'es', fallbackLocale: 'es', messages, });

export default i18n
