import FrontendPanel from 'Layout/FrontendPanel'

// dashboard components
const Home = () => import('Views/dashboard/Home');
const About = () => import('Views/pages/About');
const Directory = () => import('Views/pages/Directory');
const Asociations = () => import('Views/pages/Asociations');
const Publications = () => import('Views/pages/Publications');
const PrivacyPolicies = () => import('Views/pages/PrivacyPolicies');

export default {
   path: '/',
   component: FrontendPanel,
   redirect: '/',
   children: [
      {
         path: '/',
         name:'Home',
         component: Home,
         meta: {
            title: 'Home'
         }
      },
      {
         path: '/about',
         name:'About',
         component: About,
         meta: {
            title: 'About'
         }
      },
      {
         path: '/directory',
         name:'Directory',
         component: Directory,
         meta: {
            title: 'Directory'
         }
      },
      {
         path: '/asociations/:slug',
         name:'Asosiations',
         component: Asociations,
         meta: {
            title: 'Asociations'
         }
      },
      {
         path: '/news',
         name:'Publications',
         component: Publications,
         meta: {
            title: 'Noticas'
         }
      },
      {
         path: '/privacy-policies',
         name:'PrivacyPolicies',
         component: PrivacyPolicies,
         meta: {
            title: 'Políticas de privacidad'
         }
      },
   ]
}
