<!-- Header Structure -->
<template>
  <header class="header-global" id="header" :class="headerClasses">
    <nav
      id="navbar-main"
      class="navbar fixed-top navbar-main navbar-expand-lg navbar-transparent navbar-light headroom"
      :class="navClasses"
    >
      <div class="container p-2">
        <div id="logo">
          <router-link to="/"
            ><img
              src="/static/images/fiaf-logo.svg"
              style="max-height: 70px; max-width: 100%"
              alt="logo"
          /></router-link>
        </div>
        <button
          @click.prevent="toggler = true"
          class="navbar-toggler"
          type="button"
          :class="{ collapsed: toggler }"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="navbar-collapse collapse"
          :class="{ show: toggler }"
          id="navbar_global"
        >
          <div class="navbar-collapse-header">
            <div class="row mx-0 no-gutters">
              <div class="col-12 collapse-brand">
                <div id="logo">
                  <router-link to="/"
                    ><img
                      src="/static/images/fiaf-logo.svg"
                      style="max-height: 100px; max-width: 100%"
                      alt="logo"
                  /></router-link>
                </div>
              </div>
              <button
                @click.prevent="toggler = false"
                type="button"
                class="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbar_global"
                aria-controls="navbar_global"
                aria-expanded="true"
                aria-label="Toggle navigation"
              >
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
          <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-auto">
            <app-menu @itemSelected="toggler = false"></app-menu>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
  import Menu from 'Components/TheHeaderMenu/Menu.vue'
  export default {
    components: {
      appMenu: Menu,
    },
    data() {
      return {
        toggler: false,
      }
    },
    mounted() {
      this.$nextTick(() => {
        window.addEventListener('scroll', this.handleScroll, { passive: true })
      })
    },
    computed: {
      headerClasses() {
        if (this.$route.path.includes('/admin')) {
          return 'admin-header'
        } else {
          return ''
        }
      },
      navClasses() {
        if (this.$route.path.includes('/admin')) {
          return ''
        } else {
          return 'custom-fixed-top'
        }
      },
    },
    methods: {
      handleScroll() {
        let navbar = document.getElementById('navbar-main')
        let sticky = navbar.offsetTop
        if (!this.$route.path.includes('/admin') && window.pageYOffset > sticky) {
          navbar.classList.add('sticky')
        } else {
          navbar.classList.remove('sticky')
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .custom-fixed-top {
    position: inherit;
  }
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }
  .navbar-main {
    background: #03184d;
  }
  .navbar-collapse .navbar-toggler {
    position: absolute;
    right: 10px;
    top: 10px;
  }
</style>
