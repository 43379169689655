import colors from 'vuetify/es5/util/colors';

export default {
  primary: {
    base: '#0c2c7c',
    darken1: colors.cyan.darken3,
    lighten1: colors.cyan.lighten3,
  },
  secondary: {
    base: '#f4f5f7',
    darken1: colors.orange.darken3,
    lighten1: colors.orange.lighten3,
  },
  primaryBgText: colors.shades.white,
  secondaryBgText: colors.shades.white,
  dark: false,
  semidark: false,
  rtl: false,
  locale: 'es'
};
