export default {
  initialiseStore(state) {
    if (localStorage.getItem('lang')) {
      state.locale = localStorage.getItem('lang')
    }
    if (JSON.parse(localStorage.getItem('vue-session-key'))) {
      if (JSON.parse(localStorage.getItem('vue-session-key')).roles) {
        state.userRoles = JSON.parse(localStorage.getItem('vue-session-key')).roles
      } else {
        state.userRoles = ''
      }
    }
  },
  updateUserRoles: (state, roles) => {
    state.userRoles = roles
  },
};
