<!-- Header Structure -->
<template>
  <nav id="navigation" class="style-1" data-app>
    <ul id="responsive">
      <li
        @click.prevent="$emit('itemSelected')"
        v-for="menuitem in menuItems"
        v-bind:key="menuitem.name"
        :class="{ 'open-menu': selectedMenu == menuitem.name }"
      >
        <router-link
          class="current"
          :to="menuitem.state"
          v-if="menuitem.type === 'link'"
          >{{ $t(`Components.Menu.${menuitem.name}`) }}</router-link
        >
        <a
          class="current"
          v-if="menuitem.type === 'sub'"
          @click="menuClick(menuitem.name)"
          >{{ $t(`Components.Menu.${menuitem.name}`) }}</a
        >
        <ul v-if="menuitem.type === 'sub'">
          <li
            @click.prevent="$emit('itemSelected')"
            v-for="childitem in menuitem.children"
            v-bind:key="childitem.name"
            :class="{ 'open-menu': selectedSubMenu == childitem.name }"
          >
            <router-link :to="childitem.state" v-if="childitem.type === 'link'">{{
              $t(`Components.Menu.${childitem.name}`)
            }}</router-link>
            <a v-if="childitem.type === 'sub'" @click="subMenuClick(childitem.name)">{{
              $t(`Components.Menu.${childitem.name}`)
            }}</a>
            <ul v-if="childitem.type === 'sub'">
              <li
                @click.prevent="$emit('itemSelected')"
                v-for="subchilditem in childitem.children"
                v-bind:key="subchilditem.name"
              >
                <router-link :to="subchilditem.state">{{
                  $t(`Components.Menu.${subchilditem.name}`)
                }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="submenu" :class="{ 'open-menu': selectedMenu == 'languaje' }">
        <a class="current" @click="menuClick('languaje')"
          ><img class="flag" :src="displayLocale.flag" :alt="'flag'"
        /></a>
        <ul>
          <li>
            <a
              @click="changeLocale('en')"
              class="router-link-exact-active router-link-active"
              ><img
                class="flag"
                :src="'https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg'"
                :alt="'flag'"
              />English</a
            >
          </li>
          <li>
            <a
              @click="changeLocale('es')"
              class="router-link-exact-active router-link-active"
              ><img
                class="flag"
                :src="'https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg'"
                :alt="'flag'"
              />Español</a
            >
          </li>
          <li>
            <a
              @click="changeLocale('pt')"
              class="router-link-exact-active router-link-active"
              ><img
                class="flag"
                :src="'https://upload.wikimedia.org/wikipedia/en/0/05/Flag_of_Brazil.svg'"
                :alt="'flag'"
              />Português</a
            >
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { menus } from './menu-items.js'
  export default {
    data() {
      return {
        menuItems: menus,
        selectedMenu: null,
        selectedSubMenu: null,
      }
    },
    methods: {
      menuClick(value) {
        if (this.selectedMenu == value) {
          this.selectedMenu = null
        } else {
          this.selectedMenu = value
        }
      },

      subMenuClick(value) {
        if (this.selectedSubMenu == value) {
          this.selectedSubMenu = null
        } else {
          this.selectedSubMenu = value
        }
      },
      changeLocale(locale) {
        this.$store.dispatch('setLocale', locale)
      },
    },
    computed: {
      ...mapGetters(['locale']),

      displayLocale() {
        switch (this.locale) {
          case 'en':
            return {
              lang: 'English',
              flag:
                'https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg',
            }
          case 'es':
            return {
              lang: 'Español',
              flag: 'https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg',
            }
          case 'pt':
            return {
              lang: 'Português',
              flag: 'https://upload.wikimedia.org/wikipedia/en/0/05/Flag_of_Brazil.svg',
            }
          default:
            return {
              lang: 'English',
              flag:
                'https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg',
            }
        }
        return other
      },
    },
  }
</script>
<style lang="scss" scoped>
  .flag {
    width: 40px;
    height: 30px;
    margin-right: 5px;
  }
  #responsive {
    height: auto;
    li:after {
      color: transparent;
    }
  }
  #responsive .submenu:after {
    color: #fff;
  }
</style>
