import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import translation from './modules/translation';
import theme from './modules/theme';

Vue.use( Vuex );

export default new Vuex.Store( {
  state: state,
  getters,
  mutations,
  actions,
  modules: {
    scheme: {
      namespaced: true,
      modules: {
        theme,
      },
    },
    translation,
  },
} );
